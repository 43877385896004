import React, { useState } from 'react';
import { storage } from '../firebase';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import Popup from './vaccinePopupInfo';
import EditableTable from './editableTable';



function UploadForm() {
    const [progress, setProgress] = useState(0);
    const [scannedText, setScannedText] = useState(''); // Store the scanned text
    const [showPopUp, setShowPopUp] = useState(false); // Control pop-up visibility
    const [parsedOCRData, setParsedOCRData] = useState('');



  const formHandler = (e) => {
    e.preventDefault();
    const file = e.target[0].files[0];
    uploadFiles(file);
  };

  const parseOCRData = (ocrText) => {
    const vaccines = [];
    const lines = ocrText.split('\n').map(line => line.trim()); // Split by newlines and trim
  
    let currentVaccine = null;
    const dateRegex = /^(\d{2}\/\d{2}\/\d{2,4})$/;
    const batchRegex = /Lot: (\S+)/i;
    const siteRegex = /Site: (.+)/i;
    const adminRegex = /Administered by: (.+)/i;
  
    lines.forEach(line => {
      const dateMatch = line.match(dateRegex);
      const batchMatch = line.match(batchRegex);
      const siteMatch = line.match(siteRegex);
      const adminMatch = line.match(adminRegex);
  
      if (!dateMatch && !batchMatch && !siteMatch && !adminMatch && line.length > 0) {
        // If it's not a date or any known field, treat it as a new vaccine name
        if (currentVaccine) {
          vaccines.push(currentVaccine); // Push the previous vaccine before starting a new one
        }
        currentVaccine = {
          vaccineName: line,
          vaccinationEvents: []
        };
      } else if (dateMatch && currentVaccine) {
        // Add a new vaccination event for the current vaccine
        currentVaccine.vaccinationEvents.push({
          date: dateMatch[1],
          batchNumber: batchMatch ? batchMatch[1] : '',
          site: siteMatch ? siteMatch[1] : '',
          adminName: adminMatch ? adminMatch[1] : ''
        });
      }
    });
  
    // Push the last vaccine record if not already added
    if (currentVaccine) {
      vaccines.push(currentVaccine);
    }
  
    return vaccines;
  };
  
  

  const uploadFiles = (file) => {
    //
    if (!file) return;
    const sotrageRef = ref(storage, `files/${file.name}`);
    const uploadTask = uploadBytesResumable(sotrageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const prog = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress(prog);
      },
      (error) => console.log(error),
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          console.log("File available at", downloadURL);

          handleDownloadURL(downloadURL);
        });
      }
    );

    const handleDownloadURL = async (downloadURL) => {
      let obj = {fileUrl: downloadURL};
      let js = JSON.stringify(obj);
      
      try {
        const response = await fetch('http://localhost:3000/api/ocr', {
          method: 'POST',
          body: js,
          headers: {
            'Content-Type': 'application/json',
          }
        });
    
        // Parse the JSON response from the server
        const data = await response.json();
    
        if (!response.ok) {
          throw new Error(data.error || 'Failed to scan text');
        }

        console.log(data);

        setScannedText(data.text);
        setShowPopUp(true);
        const example_data = parseOCRData(data.text);
        console.log(example_data);
        setParsedOCRData(example_data);
        //console.log(parsedOCRData);

      } catch (error) {
        console.error('Error scanning text from image:', error);
        throw error;
      }
    }
  };

    return (
        <div>
            <form onSubmit={formHandler}>
                <input type="file" className="input" />
                <button type="submit">Upload</button>
            </form>
            <hr />
            <h2>Uploading done {progress}%</h2>

            {showPopUp && (
                <Popup text={scannedText} onClose={() => setShowPopUp(false)} />
            )}

            <EditableTable ocrData={parsedOCRData} />



        </div>
    );
}

export default UploadForm;