import React, { useState, useEffect } from 'react';

// Function to convert from MM/dd/yy to yyyy-MM-dd
const convertToISOFormat = (dateStr) => {
  const [month, day, year] = dateStr.split('/');
  const fullYear = year.length === 2 ? `20${year}` : year;  // Convert 2-digit year to 4-digit
  return `${fullYear}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
};

// Function to convert from yyyy-MM-dd to MM/dd/yy
const convertToMMDDYYFormat = (isoDateStr) => {
  const [year, month, day] = isoDateStr.split('-');
  return `${month}/${day}/${year.slice(2)}`;
};

const EditableTable = ({ ocrData }) => {
  const [tableData, setTableData] = useState([
    {
      vaccineName: '',
      vaccinationEvents: [
        { date: '', batchNumber: '', site: '', adminName: '' }
      ]
    }
  ]);

  useEffect(() => {
    if (ocrData && ocrData.length > 0) {
      const formattedData = ocrData.map(vaccine => ({
        ...vaccine,
        vaccinationEvents: vaccine.vaccinationEvents.map(event => ({
          ...event,
          date: convertToISOFormat(event.date) // Convert to yyyy-MM-dd for display
        }))
      }));
      setTableData(formattedData);
    }
  }, [ocrData]);

  const handleChange = (e, rowIndex, field, eventIndex = null) => {
    const updatedTable = [...tableData];
    
    if (eventIndex !== null) {
      // Update specific event data
      updatedTable[rowIndex].vaccinationEvents[eventIndex][field] = field === 'date' 
        ? convertToMMDDYYFormat(e.target.value)  // Convert date back to MM/dd/yy
        : e.target.value;
    } else {
      // Update vaccine name
      updatedTable[rowIndex][field] = e.target.value;
    }

    setTableData(updatedTable);
  };

  const addVaccinationEvent = (rowIndex) => {
    const updatedTable = [...tableData];
    updatedTable[rowIndex].vaccinationEvents.push({ date: '', batchNumber: '', site: '', adminName: '' });
    setTableData(updatedTable);
  };

  const addRow = () => {
    setTableData([
      ...tableData,
      { vaccineName: '', vaccinationEvents: [{ date: '', batchNumber: '', site: '', adminName: '' }] }
    ]);
  };

  return (
    <div>
      <table style={{ borderCollapse: 'collapse', width: '100%' }}>
        <thead>
          <tr>
            <th>Vaccine Name</th>
            <th>Vaccination Date(s)</th>
            <th>Vaccine Batch/Lot Number</th>
            <th>Site of Injection</th>
            <th>Name of Person Administering</th>
          </tr>
        </thead>
        <tbody>
          {tableData.map((row, rowIndex) => (
            <React.Fragment key={rowIndex}>
              {row.vaccinationEvents.map((event, eventIndex) => (
                <tr key={eventIndex}>
                  {eventIndex === 0 && (
                    <td rowSpan={row.vaccinationEvents.length}>
                      <input
                        type="text"
                        value={row.vaccineName}
                        onChange={(e) => handleChange(e, rowIndex, 'vaccineName')}
                        style={{ width: '100%' }}
                      />
                    </td>
                  )}
                  <td>
                    <input
                      type="date"
                      value={event.date}
                      onChange={(e) => handleChange(e, rowIndex, 'date', eventIndex)}
                      style={{ width: '100%' }}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      value={event.batchNumber}
                      onChange={(e) => handleChange(e, rowIndex, 'batchNumber', eventIndex)}
                      style={{ width: '100%' }}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      value={event.site}
                      onChange={(e) => handleChange(e, rowIndex, 'site', eventIndex)}
                      style={{ width: '100%' }}
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      value={event.adminName}
                      onChange={(e) => handleChange(e, rowIndex, 'adminName', eventIndex)}
                      style={{ width: '100%' }}
                    />
                  </td>
                </tr>
              ))}
              <tr>
                <td colSpan="5">
                  <button onClick={() => addVaccinationEvent(rowIndex)}>Add Another Date</button>
                </td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </table>
      <button onClick={addRow}>Add New Vaccine</button>
    </div>
  );
};

export default EditableTable;
