import React from 'react';
import '../styles/vaccinePopupInfo.css'

function PopUp({ text, onClose }) {
    return (
        <div className="popup-overlay">
            <div className="popup-content">
                <h3>Scanned Text</h3>
                <p>{text}</p>
                <button onClick={onClose}>Close</button>
            </div>
        </div>
    );
}

export default PopUp;
