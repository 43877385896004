import React, { useState } from "react";
import UploadForm from '../components/uploadForm';
import EditableTable from "../components/editableTable";


const UploadPage = () => {
    return (
        <>
            <UploadForm />
        </>
    );
};

export default UploadPage;