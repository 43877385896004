import React from 'react'
import '../styles/businessCardUI.css'

const Card = props => {
    return (
        <div className='card text-left shadow'>

            <div className='card-body text-dark'>
                <div className='stat'>
                    {props.stat}
                </div>

                <div className='card-title' id='main'>
                    {props.title}
                </div>

                <div className='card-title text-secondary'>
                    {props.body}
                </div>

            </div>

        </div>
    );
};

export default Card;
