import {React, Component} from "react";
import Card from './businessCardUI'

import '../styles/serviceCards.css'

class Cards extends Component {
    render() {
        return(
            <div className="wrapper">
                <Card stat="000+" title="Verified Specialist" body="Highly Verified" />
                <Card stat="000+" title="Potential Customers" body="High Performance" />
                <Card stat="90%" title="Positive Feedback" body="Customers Approve" />
            </div>
        )
    }

}

export default Cards;