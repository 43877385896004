import {React, Component} from "react";
import Card from './serviceCardUI'
import Search from '../icons/Search.png'
import Calendar from '../icons/Calendar.png'
import Star from '../icons/Star.png'
import Profile from '../icons/Profile.png'
import '../styles/serviceCards.css'

class Cards extends Component {
    render() {
        return(
            <div className="wrapper">
                <Card imgsrc={Search} title="Data Entry with AI powered OCR"  />
                <Card imgsrc={Profile} title="Auto-Translate"  />
                <Card imgsrc={Calendar} title="Shareable" />
                <Card imgsrc={Star} title="True Portability for safety"  />
            </div>
        )
    }

}

export default Cards;