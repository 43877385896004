import React from 'react'
import "../styles/servicesSection.css"
import "../styles/homeNavBar.css"
import Cards from './businessCards'

function servicesSection() {
    return (
        <div className='service-wrapper'>
            <span className='top-title'>Business Figures</span>
            <span className='bottom-title'>We provide vaccine-related information</span>
            <Cards/>
            

        </div>
    );
}

export default servicesSection