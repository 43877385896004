import React from 'react'
import "../styles/introSection.css"
import "../styles/homeNavBar.css"
import { ReactComponent as Graphic} from '../assets/introGraphic.svg';
import { ReactComponent as BlueBlob} from '../assets/blue-blob.svg';


function introSection() {
    return (
        <div className="wrapper" >
            
            {/* think about making blue blob the background of main txt*/}
            <div className='side' id="left">
                <div className="main-txt">
                    Hassle-Free Universal <br/>Vaccine Records <br/>For <span id="everyone-txt">Everyone</span>
                </div>

                <div className='sub-txt'>
                Vaccine Genie is a new way to get your vaccine records across borders. We offer tools similar to those
    provided by insurance companies for free and prices are based on donations and not
    restrictive health plan networks.
                </div>

                <button className='but' id="about-us" style={{ display: 'none' }}>About Us</button>
            </div>

            <div className='side' id="graphic-svg-container">
                <Graphic id="intro-graphic"/>

            </div>

        </div>
    );
}

export default introSection;