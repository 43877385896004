import React from "react";
import {ReactComponent as Jpeg} from '../icons/file_type.svg'
import {ReactComponent as Arrow} from '../icons/Arrow.svg'

import '../styles/infoSlide.css'

const Slide = props => {
    return (
        <div className="slide-container">

            <div className="info">
                <div className="slide-title">
                    {props.title}<Jpeg/>
                </div>

                <div className="slide-info">
                    {props.company} <br/>
                    {props.date}

                </div>
            </div>

            <Arrow />

        </div>
    );
};

export default Slide;