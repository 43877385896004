import React from "react";
import '../styles/ImmunizationExample.css'
import Slides from './infoSlides'

function immunizationExample() {
    return (
        <div className="immune-wrapper">
            <Slides/>
        </div>

    );
};

export default immunizationExample;