import { initializeApp, getApps, getApp } from 'firebase/app';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyBumKMsH6236VFCme3WXQH6GxLbvgl67no",
  authDomain: "vaccine-genie.firebaseapp.com",
  databaseURL: "https://local-vg-website-default-rtdb.firebaseio.com",
  projectId: "vaccine-genie",
  storageBucket: "vaccine-genie.appspot.com",
  messagingSenderId: "596379378731",
  appId: "1:596379378731:web:034f82dc7a723f53ac366e",
  measurementId: "G-HY570Q9HZW"
};

// Initialize Firebase
let app = initializeApp(firebaseConfig);
const storage = getStorage(app);

export { storage, app };