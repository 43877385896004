import {React, Component} from "react";
import Slide from './infoSlide'
import '../styles/infoSlides.css'

class Slides extends Component {
    render() {
        return(
            <div className="slide-wrapper">
                <Slide title="MR" company="N/A" date="08/20/01" />
                <Slide title="Influenza" company="N/A" date="10/13/20" />
                <Slide title="COVID-19 Vaccination" company="Pfizer-BioNTech" date="10/11/21" />
                <Slide title="COVID-19 Vaccination" company="Pfizer-BioNTech" date="08/30/21" />

            </div>
        )
    }

}

export default Slides;