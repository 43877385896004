import React from 'react'
import "../styles/findVG.css"
import { Dropdown } from 'react-bootstrap';
import { ReactComponent as Check} from '../icons/Check.svg';
import Example from './immunizationExample';


function findVG() {
    return (
        <div className='find-wrapper'>
            <div className='left-find' >
                <span className='top-title'>Find Vaccine Genie</span>
                <span className='bottom-title'>Vaccines can prevent illness</span>

                <div className='body-info'>

                    <div className='sub-text'>Lorem ipsum dolor sit amet consectetur. Rutrum feugiat orci dignissim malesuada morbi est rutrum. Leo platea bibendum purus lectus non tellus. Morbi sed nisi vivamus id neque sit et neque. Posuere facilisis ut tristique hendrerit viverra dui lectus.</div>
                
                    <div className='checked-info'> <Check/> Missing vaccination records can delay students from starting schools and camps on time  </div>
                    <div className='checked-info'> <Check/> Language barriers makes translating vaccines difficult  </div>

                    <button className='but' id="about-us" style={{ display: 'none' }}>Download the app</button>
                </div>

            </div>

            <div className='right'>
                <Dropdown>
                    <Dropdown.Toggle variant="white" id="dropdown-basic" style={{ display:'none' }}>
                        Select Language
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        <Dropdown.Item href="#/action-1">English</Dropdown.Item>
                        <Dropdown.Item href="#/action-2">Chinese</Dropdown.Item>
                        <Dropdown.Item href="#/action-3">Spanish</Dropdown.Item>
                        <Dropdown.Item href="#/action-3">Korean</Dropdown.Item>

                    </Dropdown.Menu>
                </Dropdown>

                <Example/>
                
            </div>

            
            

        </div>
    );
}

export default findVG