import React, { useState } from 'react';
import '../styles/Homepage.css';
import NavBar from '../components/homeNavBar';
import UploadForm from '../components/uploadForm';
import IntroSection from '../components/introSection';
import ServicesSection from '../components/servicesSection';
import FindVGSection from '../components/findVG';
import TeamSection from '../components/teamSection';
import BusinessSection from '../components/businessSection';
import Footer from '../components/Footer';
import FeedbackSection from '../components/feedbackSection';


const VaccineGenieHomePage = () => {
  const [isNavOpen, setIsNavOpen] = useState(false);

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  return (
    <>
      
      <NavBar/>
      <div id="home"><IntroSection/></div>
      <div id="services"><ServicesSection/></div>
      <div id="team"><TeamSection/></div>
      <div id="work"><FindVGSection/></div>
      <BusinessSection/>
      <FeedbackSection/>
      <Footer/>
      <div id="google_translate_element"></div>




      
      
    </>
  );
};

export default VaccineGenieHomePage;