import React from 'react'
import "../styles/servicesSection.css"
import "../styles/homeNavBar.css"
import Cards from './serviceCards'

function servicesSection() {
    return (
        <div className='service-wrapper' >
            <span className='top-title'>Our Service</span>
            <span className='bottom-title'>Step by step to get your solutions</span>
            <Cards/>
            

        </div>
    );
}

export default servicesSection